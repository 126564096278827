import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Button, IconButton, Paper, Stack, Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import { process, State } from '@progress/kendo-data-query';
import { GridColumn as Column, Grid, GridDataStateChangeEvent, GridRowDoubleClickEvent } from "@progress/kendo-react-grid";
import axios from 'axios';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators, Dispatch } from 'redux';
import { APIConstantList } from '../../AppConstants';
import FeeScheduleViewModel from '../../models/FeeSchedule/FeeScheduleViewModel';
import { getHeader, PORTAL_API_URL } from '../../service/Base.Service';
import * as FeeScheduleAction from '../../store/actions/FeeSchedule.Actions';
const classNames = require('classnames');
interface IPageProps {
    classes?: any;
    theme?: any;
    history?: any;
    getFeeSchedule: (param: string | null) => void;
    setRowPerPage: (param: any) => void;
    feeScheduleList: FeeScheduleViewModel[]
    feeScheduleListLoading: boolean;
    feeScheduleListRowPerPage: any;
}

interface IPageState {
    feeScheduleList: FeeScheduleViewModel[];
    feeScheduleTablePage: number,
    feeScheduleTableRowsPerPage: number,
    pageStartCount: number,
    anchorEl: any;
    selectedFilterMenuIndex: number,
    filterMenuOptions: any[],
    selectedFilterMenu: string | number | boolean;
    selectedFilterMenuKey: string;
    searchBoxValue: string;
    searchBoxFilterKey: string,
    dataState: State;
}
class FeeScheduleGrid extends React.Component<RouteComponentProps & IPageProps, IPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            feeScheduleTablePage: 0,
            feeScheduleTableRowsPerPage: 10,
            pageStartCount: 10,
            anchorEl: null,
            selectedFilterMenuIndex: 0,
            filterMenuOptions: [{ "label": 'Serial Number', "key": 'serialNumber' }, { "label": 'AccountId', "key": 'accountId' }, { "label": 'Name', "key": 'name' }, { "label": 'Is ICP', "key": 'isICP' }],
            selectedFilterMenu: 'Serial Number',
            selectedFilterMenuKey: 'serialNumber',
            searchBoxValue: '',
            searchBoxFilterKey: '',
            feeScheduleList: this.props.feeScheduleList || [],
            dataState: {
                sort: [{ field: "code" }],
                take: 10,
                skip: 0,
            }
        }
    }

    public async componentWillMount() {
        this.props.getFeeSchedule(null);
    }

    private handleChangePage = (event: any, page: number) => {
        console.log('')
    };
    private handleChangeTableRowsPerPage = async (event: any) => {
        this.props.setRowPerPage(event.target.value);
        this.setState({ feeScheduleTableRowsPerPage: event.target.value, pageStartCount: event.target.value, feeScheduleTablePage: 0 });
    };
    private loadNextPage = async () => {
        this.setState({ feeScheduleTablePage: this.state.feeScheduleTablePage + 1, pageStartCount: this.state.pageStartCount + this.state.feeScheduleTableRowsPerPage });
    }

    private loadPreviousPage = () => {
        this.setState({ feeScheduleTablePage: this.state.feeScheduleTablePage - 1, pageStartCount: this.state.pageStartCount - this.state.feeScheduleTableRowsPerPage })
    }

    private editProductRecord = (feeSchedule: any) => {
        this.props.history.push(`/edit-fee-schedule/${feeSchedule.serialNumber}`);
    }

    private handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    private handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: any) => {
        this.setState({ selectedFilterMenuIndex: index, anchorEl: null, selectedFilterMenu: option.label, selectedFilterMenuKey: option.key, searchBoxFilterKey: option.key }, () => {
            if (this.state.searchBoxFilterKey === 'isICP') {
                this.callSearchByParam();
            }
        })
    };
    private handleClose = () => {
        this.setState({ anchorEl: null, });
    }
    private handleSearchBox = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    private onEnterKeyPress = (event: any) => {
        if (event.keyCode === 13 || event.keyCode === '13' || event.key === 'Enter') {
            this.callSearchByParam();
        }
    }
    private callSearchByParam = () => {
        const paramName = this.state.searchBoxFilterKey ? this.state.searchBoxFilterKey : this.state.selectedFilterMenuKey;
        let paramNameWithValue = `${paramName}=${this.state.searchBoxValue}`;
        if (this.state.searchBoxFilterKey === 'isICP') {
            paramNameWithValue = `${paramName}=true`;
        }
        this.props.getFeeSchedule(paramNameWithValue);
    }
    private addFee = () => {
        this.props.history.push('/fee-schedule-create')
    }

    private deleteAccount = async (fee: any) => {
        const headers = await getHeader();
        axios.delete(`${PORTAL_API_URL + APIConstantList.deleteFeeScheduleList}/${fee.serialNumber}`, {
            headers: headers,
        }).then((response: any) => {
            if (response) {
                this.props.getFeeSchedule(null);
            }
        }).catch((error) => { });
    }
    private deleteButton = (props: any) => {
        return (
            <td className="k-command-cell">
                <IconButton type="button" onClick={() => this.editProductRecord(props.dataItem)} aria-label="ModeEditIcon">
                    <ModeEditIcon />
                </IconButton>
                {props.dataItem && props.dataItem.isActive &&
                    <IconButton type="button" onClick={() => this.deleteAccount(props.dataItem)} aria-label="DeleteIcon">
                        <DeleteIcon htmlColor='red' />
                    </IconButton>
                }

            </td>
        );
    };
    private appendDeleteSerialNumber = (props: any) => {
        const { dataItem } = props;
        if(dataItem && dataItem.isActive) {
            return (
                <td className="k-command-cell">{dataItem.serialNumber}</td>
            );
        }else {
            return (
                <td className="k-command-cell">{dataItem.serialNumber +' - Deleted'}</td>

            );
        }
      
    };

    private appendDeleteAccountId= (props: any) => {
        const { dataItem } = props;
        if(dataItem && dataItem.isActive) {
            return (
                <td className="k-command-cell">{dataItem.accountId}</td>
            );
        }else {
            return (
                <td className="k-command-cell">{dataItem.accountId +' - Deleted'}</td>

            );
        }
      
    };

    public render(): JSX.Element {
        const { selectedFilterMenuIndex, anchorEl, selectedFilterMenu, filterMenuOptions,
            searchBoxValue, } = this.state;
        const { classes, feeScheduleList, feeScheduleListLoading, feeScheduleListRowPerPage } = this.props;


        return (<Paper className={classNames(classes.paper, classes.feeSchedule, 'container')} style={(feeScheduleList?.length === 0) ? { height: '70vh' } : {}} elevation={5} square={false}>
            <Stack
                direction="column"
                justifyContent="space-around"
                alignItems="flex-end"
                spacing={2}
            >
                <Button color="primary" style={{ backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c', margin: 10 }} onClick={this.addFee} >Add        </Button>
            </Stack>
            <Grid
                editField={"inEdit"}
                resizable={true}
                pageable={true}
                sortable={true}
                filterable={true}
                style={{ height: "400px" }}
                data={process(feeScheduleList, this.state.dataState)}
                {...this.state.dataState}
                onRowDoubleClick={(e: GridRowDoubleClickEvent) => {
                    this.editProductRecord(e.dataItem)
                }}
                onDataStateChange={(e: GridDataStateChangeEvent) => {
                    this.setState({ dataState: e.dataState });
                }}
            >
                <Column field="displaySerialNumber" title="Serial Number" width="110px"   minResizableWidth={90} 
                // cell={this.appendDeleteSerialNumber} 
                />
                <Column field="displayAccountId" title="AccountId" width="150px"
                //  cell={this.appendDeleteAccountId}
                 />
                <Column field="isICP" title="Is ICP" filter='boolean' width="90px" />
                <Column field="pushnotify" title="Push Notify" filter='boolean' width="90px" />
                <Column field="excludeGPay" title="Exclude GPay" filter='boolean' width="90px" />
                <Column field="name" title="Name" width="200px" />
                <Column field="cardholder_ic_rate" filter='numeric' title="Card Holder IC Rate %" width="120px" />
                <Column field="cardholder_tran_rate" filter='numeric' title="Card Holder Tran Rate $" width="120px" />
                <Column field="merchant_ic_rate" filter='numeric' title="Merchant IC Rate %" width="120px" />
                <Column field="merchant_tran_rate" filter='numeric' title="Merchant Tran Rate $" width="120px" />
                <Column field="ach_ic_rate" filter='numeric' title="Ach IC Rate %" width="130px" />
                <Column field="ach_tran_rate" title="Ach Tran Rate $" filter='numeric' width="120px" />
                <Column field="cardholder_icplus_tran_rate" filter='numeric' title="Cardholder IC+ Tran Rate $" width="120px" />
                <Column field="cardholder_icplus_rate" filter='numeric' title="Cardholder IC+ Rate %" width="120px" />
                <Column cell={this.deleteButton} filterable={false} title="Actions" />
            </Grid>


            {/* <SearchBox handleClickListItem={this.handleClickListItem} anchorEl={anchorEl}
                handleClose={this.handleClose} filterMenuOptions={filterMenuOptions} selectedFilterMenuIndex={selectedFilterMenuIndex}
                handleMenuItemClick={this.handleMenuItemClick} selectedFilterMenu={selectedFilterMenu} searchBoxValue={searchBoxValue}
                buttonAddFunction={this.addFee} buttonAddLabel={'Add'}
                isDisabledSearchBox={this.state.searchBoxFilterKey === 'isICP'}
                handleSearchBox={this.handleSearchBox} onEnterKeyPress={this.onEnterKeyPress} />
            <hr style={{ color: 'rgb(224 224 224)', border: '1px solid' }} />
            <Typography component="div" className={classes.tabelContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeaderBg}>Serial Number</TableCell>
                            <TableCell className={classes.tableHeaderBg}>AccountId</TableCell>
                            <TableCell className={classes.tableHeaderBg}>Is ICP</TableCell>
                            <TableCell className={classes.tableHeaderBg}>Name</TableCell>
                            <TableCell className={classes.tableHeaderBg}>Card Holder IC Rate %</TableCell>
                            <TableCell className={classes.tableHeaderBg}>Card Holder Tran Rate $</TableCell>
                            <TableCell className={classes.tableHeaderBg}>Merchant IC Rate %</TableCell>
                            <TableCell className={classes.tableHeaderBg}>Merchant Tran Rate $</TableCell>
                            <TableCell className={classes.tableHeaderBg}>Ach IC Rate %</TableCell>
                            <TableCell className={classes.tableHeaderBg}>Ach Tran Rate $</TableCell>
                            <TableCell className={classes.tableHeaderBg}>Cardholder IC+ Rate %</TableCell>
                            <TableCell className={classes.tableHeaderBg}>Cardholder IC+ Tran Rate $</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(Array.isArray(feeScheduleList) && feeScheduleList.length > 0) ?
                            feeScheduleList.slice(this.state.feeScheduleTablePage * feeScheduleListRowPerPage,
                                this.state.feeScheduleTablePage * feeScheduleListRowPerPage + feeScheduleListRowPerPage).map((feeSchedule: any, feeScheduleIndex: number) => {
                                    return (
                                        <TableRow key={`Prod${feeScheduleIndex}`} onDoubleClick={() => this.editProductRecord(feeSchedule)}>
                                            <TableCell>{feeSchedule.serialNumber}</TableCell>
                                            <TableCell>{feeSchedule.accountId}</TableCell>
                                            <TableCell>{`${feeSchedule.isICP}`}</TableCell>
                                            <TableCell>{feeSchedule.name}</TableCell>
                                            <TableCell>{feeSchedule.cardholder_ic_rate}</TableCell>
                                            <TableCell>{feeSchedule.cardholder_tran_rate}</TableCell>
                                            <TableCell>{feeSchedule.merchant_ic_rate}</TableCell>
                                            <TableCell>{feeSchedule.merchant_tran_rate}</TableCell>
                                            <TableCell>{feeSchedule.ach_ic_rate}</TableCell>
                                            <TableCell>{feeSchedule.ach_tran_rate}</TableCell>
                                            <TableCell>{feeSchedule.cardholder_icplus_rate}</TableCell>
                                            <TableCell>{feeSchedule.cardholder_icplus_tran_rate}</TableCell>
                                        </TableRow>
                                    );
                                }) : null}
                    </TableBody>
                </Table>
                {Array.isArray(feeScheduleList) && feeScheduleList.length === 0 && !feeScheduleListLoading && <Typography component={'div'} style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>No Record Found</Typography>}
                {(feeScheduleListLoading) &&
                    <Typography component="div" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <ProgressBar />
                    </Typography>}
                {(Array.isArray(feeScheduleList) && feeScheduleList.length > 0) &&
                    <TablePagination
                        component="div"
                        count={feeScheduleList.length}
                        rowsPerPage={feeScheduleListRowPerPage}
                        page={this.state.feeScheduleTablePage}
                        backIconButtonProps={{ 'aria-label': 'Previous Page', 'onClick': this.loadPreviousPage }}
                        nextIconButtonProps={{ 'aria-label': 'Next Page', 'onClick': this.loadNextPage, }}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeTableRowsPerPage}
                    />}
            </Typography> */}
        </Paper>)
    }

}
const styles = (theme: Theme) => ({
    paper: {
        overflow: 'hidden',
        color: theme.palette.text.secondary,
    },
    feeSchedule: {
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },
    tabelContainer: {
        padding: '1px',
        overflow: 'auto'
    },
    iconPointer: {
        cursor: 'pointer'
    },
    dialogContainer: {
        width: '50%',
        height: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            margin: 0,
            maxWidth: '100%',
            maxHeight: 'none',
            borderRadius: 0,
        },
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    tableHeaderBg: {
        backgroundColor: '#f1b665a8',
        fontWeight: 700
    }
})
const mapStateToProps = (state: any) => ({
    feeScheduleList: state.feeSchedule?.feeScheduleList,
    feeScheduleListLoading: state.feeSchedule?.feeScheduleListLoading,
    feeScheduleListRowPerPage: state.feeSchedule?.feeScheduleRowPerPage
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getFeeSchedule: FeeScheduleAction.fetchFeeScheduleList,
        setRowPerPage: FeeScheduleAction.feeScheduleListRowPerPage,
    }, dispatch);
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(FeeScheduleGrid as any)) as any);