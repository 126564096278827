import { ActionType, IAppAction } from '../store/actions/Helpers';
import * as FeeScheduleActions from '../store/actions/FeeSchedule.Actions';
import { showLoader, hideLoader } from '../store/actions/LoaderActions'
import axios from 'axios';
import { PORTAL_API_URL, getHeader } from './Base.Service';
import history from '../../@history';
import { toast } from 'react-toastify';
import { APIConstantList } from '../AppConstants';
const FeeScheduleMiddleWare = (propsApiMid: any) => (next: any) => async (action: IAppAction) => {
    const { dispatch, getState } = propsApiMid;
    const headers = await getHeader();
    switch (action.type) {
        case ActionType.GET_FEE_SCHEDULE_LIST_REQUEST:
            next(showLoader());
            axios.get(`${PORTAL_API_URL + APIConstantList.getFeeScheduleList}?${action.payload ? action.payload : ''}`, {
                headers: headers,
            }).then((response: any) => {
                next(FeeScheduleActions.fetchFeeScheduleListSuccess(response.data));
                next(hideLoader());
            }).catch((error) => {
                next(FeeScheduleActions.fetchFeeScheduleListFailure());
                next(hideLoader());
            });
            break;

        case ActionType.GET_FEE_SCHEDULE_BY_ID_REQUEST:
            next(showLoader());
            axios.get(`${PORTAL_API_URL + APIConstantList.getFeeScheduleList}?serialNumber=${action.payload}`, {
                headers: headers,
            }).then((response: any) => {
                next(FeeScheduleActions.fetchFeeScheduleBySerialNumberSuccess(response.data));
                next(hideLoader());
            }).catch((error) => {
                next(FeeScheduleActions.fetchFeeScheduleBySerialNumberFailure());
                next(hideLoader());
            });
            break;

        case ActionType.PUT_FEE_SCHEDULE_BY_ID_REQUEST:
            next(showLoader());
            axios.put(`${PORTAL_API_URL + APIConstantList.getFeeScheduleList}/${action.payload.serialNumber}`, action.payload, {
                headers: headers,
            }).then((response: any) => {
                next(FeeScheduleActions.fetchFeeScheduleBySerialNumberSuccess(response.data));
                history.push('/fee-schedule-grid')
                toast.success('Updated Successfully');
                next(hideLoader());
            }).catch((error) => {
                toast.error('something went wrong');
                next(FeeScheduleActions.fetchFeeScheduleBySerialNumberFailure());
                next(hideLoader());
            });
            break;

        case ActionType.POST_FEE_SCHEDULE_SAVE_REQUEST:
            next(showLoader());
            axios.post(`${PORTAL_API_URL + APIConstantList.getFeeScheduleList}`, action.payload, {
                headers: headers,
            }).then((response: any) => {
                next(FeeScheduleActions.fetchFeeScheduleBySerialNumberSuccess(response.data));
                history.push('/fee-schedule-grid')
                toast.success('Saved Successfully');
                next(hideLoader());
            }).catch((error) => {
                next(FeeScheduleActions.fetchFeeScheduleBySerialNumberFailure());
                toast.error('something went wrong');
                next(hideLoader());
            });
            break;
        case ActionType.PUT_RESEND_EMAIL_REQUEST:
            next(showLoader());
            axios.put(`${PORTAL_API_URL + APIConstantList.resendEmail}/${action.payload}`, {}, {
                headers: headers,
            }).then((response: any) => {
                const res: any = JSON.parse(response.data);
                if (res && Array.isArray(res) && res[0].MailSend === 'failed') {
                    toast.error('Mail Not Sent get ' + res[0].MailSend);
                } else {
                    toast.success('Mail sent successfully');
                }
                next(hideLoader());
            }).catch((error:any) => {
                next(FeeScheduleActions.fetchFeeScheduleBySerialNumberFailure());
                toast.error(error && Array.isArray(error.response.data) ? error.response.data[0].message : 'something went wrong');
                next(hideLoader());
            });
            break;

        default:
            break;
    }

    await next(action);
};

export default FeeScheduleMiddleWare;
